@use "@angular/material" as mat;
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic&amp;subset=latin-ext");

@import "@angular/material/theming";

$general-typography: mat.define-typography-config(
  $font-family: '"Open Sans", "Helvetica Neue", sans-serif',
  $body-2: mat.define-typography-level(14px, 24px, 600),
);

$secondary-typography: mat.define-typography-config(
  $font-family: "Roboto, sans-serif",
);

@include mat.core($general-typography);

$mat-dark-primary-text: rgba(0, 0, 0, 0.87);
$mat-light-primary-text: #ffffff;

$primary-color: #619bd3;
$primary: $primary-color;
$primary-lighter: $primary-color;
$primary-darker: $primary-color;

$accent-color: #9cbd3d;
$accent: $accent-color;
$accent-lighter: $accent-color;
$accent-darker: $accent-color;

$warn-color: #f72028;
$warn: $warn-color;
$warn-lighter: $warn-color;
$warn-darker: $warn-color;

$icademy-orange: #f18321;

$primary-palette: (
  main: $primary,
  lighter: $primary-lighter,
  darker: $primary-darker,
  200: $primary,
  contrast: (
    main: $mat-light-primary-text,
    lighter: $mat-dark-primary-text,
    darker: $mat-light-primary-text,
  ),
);

$warn-palette: (
  main: $warn,
  lighter: $warn-lighter,
  darker: $warn-darker,
  200: $warn,
  contrast: (
    main: $mat-light-primary-text,
    lighter: $mat-dark-primary-text,
    darker: $mat-light-primary-text,
  ),
);

$accent-palette: (
  main: $accent,
  lighter: $accent-lighter,
  darker: $accent-darker,
  200: $accent,
  contrast: (
    main: $mat-light-primary-text,
    lighter: $mat-dark-primary-text,
    darker: $mat-light-primary-text,
  ),
);

$mat-primary-palette: mat.define-palette($primary-palette, main, lighter, darker);
$mat-accent-palette: mat.define-palette($accent-palette, main, lighter, darker);
$mat-warn-palette: mat.define-palette($warn-palette, main, lighter, darker);
$default-theme: mat.define-light-theme($mat-primary-palette, $mat-accent-palette, $mat-warn-palette);

.default-theme {
  @include mat.all-component-themes($default-theme);
}

/* override themes */

.default-theme {
  mat-form-field {
    &.mat-form-field-appearance-legacy {
      &:has([readonly]) {
        // use dotted underline for readonly fields
        .mat-form-field-underline {
          background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
          background-size: 4px 100%;
          background-repeat: repeat-x;
          background-position: 0;
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }
}
