/** typeface styling */
$largeHeadLine: normal 24px/24px "HelveticaNeueLight", Helvetica, Arial, sans-serif;
$mediumHeadLine: normal 16px/24px "HelveticaNeueMedium", Helvetica, Arial, sans-serif;
$mediumHeadLineLight: normal 16px/24px "HelveticaNeueLight", Helvetica, Arial, sans-serif;
$smallHeadLine: normal 12px/16px "HelveticaNeueMedium", Helvetica, Arial, sans-serif;
$defaultText: normal 12px/16px "HelveticaNeue", Helvetica, Arial, sans-serif;
$buttonText: normal 12px/12px "HelveticaNeueMedium", Helvetica, Arial, sans-serif;
$defaultTextColor: #333333;
$lightTextColor: #cccccc;
$grayTextColor: #999999;
