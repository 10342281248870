// Blue
$darkestBlue: #00539f;
$mediumDarkBlue: #0560b4;
$mediumBlue: #2196f3;
$mediumLightBlue: #51a8ff;
$lightBlue: #71c0ff;
$lightestBlue: #b1dcff;

// Green
$darkestGreen: #006400;
$mediumDarkGreen: #008000;
$mediumGreen: #32cd32;
$mediumLightGreen: #24c28a;
$lightGreen: #7fff00;
$lightestGreen: #7cfc00;

// __<<ngThemingMigrationEscapedComment0>>__
$darkGray: #1f2126;
$mediumGray: #7a8599;
$lightGray: #a9b3c7;
$lightestGray: #d3d6de;
$white: #ffffff;

/** background variants */
$darkBG: #2b3347;
$darkBGLess: #4c5673;
$darkBGLesser: #5a6688;
$mediumBG: #353e57;
$lightBG: #535f80;
$lighterBG: #dfe3eb;
$lightestBG: #f2f4fa;
$boxShadow: rgba(0, 0, 0, 0.5);

/** gradient variants */
$gradientBlueLight: #3c8efa;
$gradientBlueDark: #266cc7;
$gradientBlueDarkHover: #1956a6;
$gradientGreen: #11a672;
$gradientGreenHover: #0f9465;
$gradientYellow: #f09800;
$gradientYellowHover: #eb8900;
$gradientRed: #e04343;
$gradientRedHover: #cc3d3d;
